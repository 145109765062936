<template>
  <div
    class="v-player--time-display"
    @click="handleClick"
  >
    <template v-if="isNumber">
      {{ time | formatTime }}
    </template>

    <template v-else>
      Live
    </template>
  </div>
</template>

<script>
import { formatTime } from '@/utils/helpers'

export default {
  name: 'TimeDisplay',

  data () {
    return {
      time: 0
    }
  },

  computed: {
    isNumber () {
      return typeof this.time === 'number'
    }
  },

  filters: { formatTime },

  mounted () {
    this.$parent.$on([
      'player:playlistloaded',
      'player:timeupdate',
      'player:seekend'
    ], this.handleTimeDisplay)
  },

  beforeDestroy () {
    this.$parent.$off([
      'player:playlistloaded',
      'player:timeupdate',
      'player:seekend'
    ], this.handleTimeDisplay)
  },

  methods: {
    handleTimeDisplay () {
      const player_ = this.$parent

      if (player_.isLive()) {
        this.time = null
      } else {
        this.time = player_.currentTime()
      }
    },

    handleClick () {
      const player_ = this.$parent

      if (player_.isLive()) {
        const seekRange = player_.getSeekRange()
        const seekRangeSize = seekRange.end - seekRange.start

        if (seekRangeSize <= 0) {
          return
        }

        player_.play(seekRange.end)
      }
    }
  }
}
</script>
