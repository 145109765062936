<template>
  <div
    :class="{
      'v-player': true,
      'v-player--ui': ui,
      'is-started': hasVideoStarted,
      'is-live': isVideoLive,
      'is-useractive': isUserActive,
      'is-dialogactive': isDialogActive,
      'is-waiting': isVideoWaiting,
      'is-seeking': isVideoSeeking,
      'is-playing': !isVideoPaused,
      'is-paused': isVideoPaused,
      'is-unload': isVideoUnload,
      'is-ended': isVideoEnded,
      'is-error': isVideoError
    }"
    tabindex="-1"
    @mousemove="handleMouseMove"
    @mouseup="handleMouseUp"
    @keydown="handleHotKeys"
  >
    <div class="v-player--video">
      <video playsinline preload="auto" ref="video"></video>
    </div>

    <div
      class="v-player--thumbnail"
      :style="{ backgroundImage: playback.thumbnail ? `url(${playback.thumbnail})` : false }"
    >
    </div>

    <div class="v-player--overlay"></div>

    <v-spinner-loader
      class="v-player--spinner-loading"
      :show="showLoading"
      absolute
    />

    <div class="v-player--skin-container" ref="panel">
      <div class="v-player--panel--top">
        <title-display />
      </div>

      <div
        class="v-player--panel--text"
        @click.self="handleTextPanelClick"
        ref="text"
      >
      </div>

      <div class="v-player--panel--bottom">
        <div class="v-player--controls--top">
          <play-toggle-button ref="playToggle" />

          <div class="v-player--progress-control">
            <time-display />
            <seek-bar ref="seekBar" />
            <duration-display />
          </div>
        </div>

        <div class="v-player--controls--bottom">
          <back-button ref="backButton" />
          <video-settings-button ref="videoSettingsDialogButton" />
          <next-episode-button ref="nextEpisodeButton" />
        </div>
      </div>
    </div>

    <big-play-button ref="bigPlayButton" />

    <skip-button
      v-model="showSkipButton"
      ref="skipButton"
    />

    <video-settings-dialog
      v-model="showVideoSettings"
      ref="videoSettingsDialog"
    />

    <error-display ref="errorDisplay" />

    <slot />
  </div>
</template>

<script>
import muxjs from 'mux.js'
window.muxjs = muxjs

import shaka from './libs/shaka-player.compiled'

import _merge from 'lodash/merge'
import _forEach from 'lodash/forEach'
import _find from 'lodash/find'

import VSpinnerLoader from '@/components/VSpinnerLoader'
import ErrorDisplay from './components/ErrorDisplay.vue'
import BigPlayButton from './components/BigPlayButton'
import TitleDisplay from './components/TitleDisplay.vue'
import PlayToggleButton from './components/PlayToggleButton.vue'
import TimeDisplay from './components/TimeDisplay.vue'
import SeekBar from './components/SeekBar.vue'
import DurationDisplay from './components/DurationDisplay.vue'
import BackButton from './components/BackButton.vue'
import VideoSettingsButton from './components/VideoSettingsButton.vue'
import VideoSettingsDialog from './components/VideoSettingsDialog.vue'
import NextEpisodeButton from './components/NextEpisodeButton.vue'
import SkipButton from './components/SkipButton.vue'

import { keyCodes } from '@/utils/helpers'

import {
  PLAYER_USER_ACTIVITY,
  // PLAYER_TIME_UPDATE
} from '@/utils/constants'

export default {
  name: 'VPlayer',

  props: {
    shakaOptions: {
      type: Object,
      default: () => ({})
    },
    autoplay: Boolean,
    ui: {
      type: Boolean,
      default: true
    },
    liveui: Boolean,
    audio: String,
    subtitle: {
      type: String,
      default: 'off'
    },
    startIndex: {
      type: Number,
      default: 0
    },
    startTime: {
      type: Number,
      default: 0
    },
    playlists: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      isPlayerReady: false,
      isUserActive: false,
      isPlaylistLoaded: false,
      isVideoCanPlay: false,
      isVideoLive: false,
      isVideoBuffering: false,
      isVideoWaiting: false,
      isVideoSeeking: false,
      isVideoPaused: true,
      isVideoUnload: false,
      isVideoEnded: false,
      isVideoError: false,
      hasVideoStarted: false,
      hasVideoPlayed: false,
      showVideoSettings: false,
      showSkipButton: false,
      playback: {
        index: 0,
        audio: null,
        subtitle: null,
        thumbnail: null,
        playlists: [],
      }
    }
  },

  computed: {
    computeShakaOptions () {
      const { audio, subtitle } = this.playback
      const hasSubtitle = subtitle !== 'off' && subtitle !== null

      let options = {
        abr: {
          restrictions: {
            maxWidth: 1920,
            maxHeight: 1080
          }
        },
        drm: {
          retryParameters: {
            maxAttempts: Infinity
          }
        },
        streaming: {
          preferNativeHls: false,
          stallEnabled: false,
          // bufferBehind: 60,
          // bufferingGoal: 30,
          rebufferingGoal: 0,
          retryParameters: {
            maxAttempts: Infinity
          }
        },
        textDisplayFactory: () => {
          return new shaka.text.UITextDisplayer(this.$refs.video, this.$refs.text)
        }
      }

      if (audio) {
        options.preferredAudioLanguage = audio
      }

      if (hasSubtitle) {
        options.preferredTextLanguage = hasSubtitle ? subtitle : ''
      }

      return _merge(options, this.shakaOptions)
    },

    isDialogActive () {
      return this.showVideoSettings
    },

    showLoading () {
      return this.hasVideoStarted && (this.isVideoBuffering || this.isVideoWaiting)
    }
  },

  watch: {
    liveui: {
      handler (active) {
        this.isVideoLive = active
      },
      immediate: true
    },
    autoplay: {
      handler (autoplay) {
        this.playback.autoplay = autoplay
      },
      immediate: true
    },
    audio: {
      handler (audio) {
        this.playback.audio = audio
      },
      immediate: true
    },
    subtitle: {
      handler (subtitle) {
        this.playback.subtitle = subtitle
      },
      immediate: true
    }
  },

  components: {
    VSpinnerLoader,
    SkipButton,
    ErrorDisplay,
    BigPlayButton,
    TitleDisplay,
    PlayToggleButton,
    TimeDisplay,
    SeekBar,
    DurationDisplay,
    BackButton,
    VideoSettingsButton,
    VideoSettingsDialog,
    NextEpisodeButton
  },

  mounted () {
    shaka.polyfill.installAll()

    if (shaka.Player.isBrowserSupported()) {
      this._setup()
    } else {
      this.handlePlayerError(new Error('Browser not supported!'))
    }
  },

  beforeDestroy () {
    this._destroy()
  },

  methods: {
    _setup () {
      const videoElement = this.$refs.video

      this.$player = new shaka.Player(videoElement)
      this.$player.configure(this.computeShakaOptions)

      this.$eventManager = new shaka.util.EventManager()
      this.$eventManager.listen(videoElement, 'loadstart', this.handleVideoLoadStart)
      this.$eventManager.listen(videoElement, 'loadeddata', this.handleVideoLoadedData)
      this.$eventManager.listen(videoElement, 'canplay', this.handleVideoCanPlay)
      this.$eventManager.listen(videoElement, 'error', this.handlePlayerError)

      this.$eventManager.listen(videoElement, 'play', this.handleVideoPlay)
      this.$eventManager.listen(videoElement, 'playing', this.handleVideoPlaying)
      this.$eventManager.listen(videoElement, 'pause', this.handleVideoPause)
      this.$eventManager.listen(videoElement, 'waiting', this.handleVideoWaiting)
      this.$eventManager.listen(videoElement, 'ended', this.handleVideoEnded)
      this.$eventManager.listen(videoElement, 'durationchange', this.handleVideoDurationChange)
      this.$eventManager.listen(videoElement, 'progress', this.handleVideoProgressUpdate)
      this.$eventManager.listen(videoElement, 'timeupdate', this.handleVideoTimeUpdate)

      this.$eventManager.listen(this.$player, 'buffering', this.handleVideoBuffering)
      this.$eventManager.listen(this.$player, 'trackschanged', this.handleTracksChanged)
      this.$eventManager.listen(this.$player, 'error', this.handlePlayerError)

      this.$el.focus()
      this.userActive(true)

      this.setIndex(this.startIndex)
      this.setPlaylists(this.playlists)

      this.isPlayerReady = true
      this.$emit('player:ready')
    },

    _destroy () {
      if (!this.$player) {
        return
      }

      this._clearUserActivity()

      if (this.$eventManager) {
        const videoElement = this.$refs.video

        this.$eventManager.unlisten(videoElement, 'loadstart', this.handleVideoLoadStart)
        this.$eventManager.unlisten(videoElement, 'loadeddata', this.handleVideoLoadedData)
        this.$eventManager.unlisten(videoElement, 'canplay', this.handleVideoCanPlay)
        this.$eventManager.unlisten(videoElement, 'error', this.handlePlayerError)

        this.$eventManager.unlisten(videoElement, 'play', this.handleVideoPlay)
        this.$eventManager.unlisten(videoElement, 'playing', this.handleVideoPlaying)
        this.$eventManager.unlisten(videoElement, 'pause', this.handleVideoPause)
        this.$eventManager.unlisten(videoElement, 'waiting', this.handleVideoWaiting)
        this.$eventManager.unlisten(videoElement, 'ended', this.handleVideoEnded)
        this.$eventManager.unlisten(videoElement, 'durationchange', this.handleVideoDurationChange)
        this.$eventManager.unlisten(videoElement, 'progress', this.handleVideoProgressUpdate)
        this.$eventManager.unlisten(videoElement, 'timeupdate', this.handleVideoTimeUpdate)

        this.$eventManager.unlisten(this.$player, 'buffering', this.handleVideoBuffering)
        this.$eventManager.unlisten(this.$player, 'trackschanged', this.handleTracksChanged)
        this.$eventManager.unlisten(this.$player, 'error', this.handlePlayerError)
      }

      this.$player.destroy()
    },

    async _loadPlaylistTrack (index, seconds) {
      const { playlists } = this.playback
      const lastIndex = playlists.length - 1

      if (typeof index === 'undefined' || index < 0) {
        index = 0
      } else if (index > lastIndex) {
        index = lastIndex
      }

      const track = playlists[index]

      if (playlists.length === 0 || !track) {
        return
      }

      const hasSourceAuto = Array.isArray(track.sources) && track.sources.length
      const hasSourceSelection = Array.isArray(track.sourceTracks) && track.sourceTracks.length

      var source = null

      if (hasSourceAuto) {
        source = track.sources[0]
      } else if (hasSourceSelection) {
        var sourceTrack = _find(track.sourceTracks, { language: this.playback.audio })

        if (!sourceTrack) {
          this.playback.audio = track.sourceTracks[0].language
          sourceTrack = track.sourceTracks[0]
        }

        source = sourceTrack.sources[0]

        this.$emit('player:sourcetrackschanged')
      }

      this.playback.index = index
      this.playback.thumbnail = track.thumbnail

      this.$emit('player:playlistonload', track)

      if (!source) {
        this.userActive(true)
        this._clearUserActivity()

        this.$player.unload(false)
          .then(() => {
            this.$emit('player:timeupdate')
            this.$emit('player:durationchanged')
          })

        this.isVideoUnload = true
        this.$emit('player:playlistunload', track)
        return
      }

      this.$player.configure({
        drm: { servers: source.keySystems ? source.keySystems : {} }
      })

      var startTime = typeof seconds === 'number' ? seconds : 0

      if (!this.hasVideoPlayed) {
        startTime = this.startTime
      }

      try {
        await this.$player.load(source.streamURL, startTime, source.streamMimeType)
      } catch (e) {
        this.handlePlayerError(e)
        return
      }

      this.isVideoLive = this.isLive()

      if (!this.isVideoLive && track.subtitles && track.subtitles.length) {
        const subtitle = this.playback.subtitle

        this._loadExternalTextTracks(track.subtitles)

        if (subtitle !== 'off') {
          this.setTextLanguage(subtitle, true)
        }
      }

      this.isPlaylistLoaded = true
      this.$emit('player:playlistloaded')

      if (this.playback.autoplay) {
        // Ensure streaming automatically plays at the beginning time.
        this.$eventManager.listenOnce(this.$refs.video, 'canplay', () => this.play())

        this.play()
      }
    },

    _loadExternalTextTracks (subtitles) {
      if (typeof subtitles === 'undefined' || subtitles.length === 0) {
        return
      }

      subtitles.forEach((subtitle) => {
        this.$player.addTextTrackAsync(subtitle.url, subtitle.language, 'subtitles', 'text/vtt', '', subtitle.label)
      })
    },

    _clearUserActivity () {
      this.handleUserActivity_ && clearTimeout(this.handleUserActivity_)
      this.handleUserActivity_ = null
    },

    handleTextPanelClick () {
      if (this.isVideoUnload || this.isVideoError) {
        return
      }

      this.userActive(true)
      this.playOrPause()
    },

    handleVideoLoadStart (e) {
      this.$emit('player:loadstart', e)
    },

    handleVideoLoadedData (e) {
      this.$player.configure({ abr: { enabled: true } })
      this.$emit('player:loadeddata', e)
    },

    handleVideoCanPlay (e) {
      this.isVideoError = false
      this.$emit('player:canplay', e)
    },

    handleVideoPlay (e) {
      this.hasVideoStarted = this.hasVideoPlayed = true
      this.$emit('player:play', e)
    },

    handleVideoPlaying (e) {
      this.isVideoPaused = this.isVideoWaiting = this.isVideoEnded = false
      this.$emit('player:playing', e)
    },

    handleVideoPause (e) {
      this.isVideoPaused = true
      this.$emit('player:pause', e)
    },

    handleVideoWaiting (e) {
      this.isVideoWaiting = true
      this.$emit('player:waiting', e)
    },

    handleVideoEnded (e) {
      const { index, playlists } = this.playback
      const hasTrack = !!playlists[index + 1]

      if (!hasTrack) {
        this.userActive(true)
        this._clearUserActivity()

        this.isVideoEnded = true
        this.$emit('player:ended', e)
      } else {
        this.nextEpisode()
      }
    },

    handleVideoTimeUpdate (e) {
      this.$emit('player:timeupdate', e)
    },

    handleVideoDurationChange (e) {
      this.$emit('player:durationchanged', e)
    },

    handleVideoProgressUpdate (e) {
      const buffered = this.getBufferedInfo()

      this.isVideoBuffering = buffered.total.length === 0
      this.$emit('player:progress', e)
    },

    handleMouseMove (e) {
      if (!this.hasVideoStarted || this.isDialogActive || this.isVideoUnload || this.isVideoError) {
        return
      }

      if (!this.isVideoPaused && !this.isVideoEnded) {
        this.userActive(true)
      }

      const seekBar = this.$refs.seekBar

      if (seekBar.isMouseChanging) {
        seekBar.update(e.clientX)
      }
    },

    handleMouseUp () {
      if (this.isVideoSeeking) {
        const seekBar = this.$refs.seekBar

        seekBar.isMouseChanging = false
        seekBar.handleVideoSeekingAfter()
      }
    },

    handleHotKeys (e) {
      const keycode = e.keyCode || e.which

      if (keycode === keyCodes.enter || keycode === keyCodes.space) {
        const playToggle = this.$refs.playToggle

        this.hasVideoStarted = true
        this.userActive(true)
        this.playOrPause()

        this.$nextTick(() => playToggle.$el.focus())
      }

      this.$emit('player:keydown', e)
    },

    handleVideoBuffering (e) {
      this.isVideoBuffering = this.$player.isBuffering()
      this.$emit('player:buffering', e)
    },

    handleTracksChanged (e) {
      this.$emit('player:trackschanged', e)
    },

    handlePlayerError (e) {
      const error = e.detail || e
      const ignoredError = [1001, 1002, 1003]

      if (this.isVideoError || this.hasVideoPlayed && ignoredError.indexOf(error.code) !== -1) {
        return
      }

      this._clearUserActivity()

      this.isVideoError = true

      if (error instanceof shaka.util.Error) {
        var message

        if (error.category === 1) {
          message = 'Errors from the network stack.'
        } else if (error.category === 2) {
          message = 'Errors parsing text streams.'
        } else if (error.category === 3) {
          message = 'Errors parsing or processing audio or video streams.'
        } else if (error.category === 4) {
          message = 'Errors parsing the Manifest.'
        } else if (error.category === 5) {
          message = 'Errors related to streaming.'
        } else if (error.category === 6) {
          message = 'Errors related to DRM.'
        } else if (error.category === 7) {
          message = 'Miscellaneous errors from the player.'
        } else if (error.category === 8) {
          message = 'Errors related to cast.'
        } else if (error.category === 9) {
          message = 'Errors in the database storage (offline).'
        } else if (error.category === 10) {
          message = 'Errors related to ad insertion.'
        }

        if (e.detail) {
          e.detail.message = message
        } else {
          e.message = message
        }
      }

      this.$emit('player:error', e)
    },

    isLive () {
      return this.liveui || this.$player && this.$player.isLive()
    },

    getBufferedInfo () {
      return this.$player && this.$player.getBufferedInfo()
    },

    getDuration () {
      return this.isLive() ? Infinity : this.$refs.video.duration
    },

    getAudioTracks () {
      const audios = this.$player.getAudioLanguages()

      var tracks = []

      _forEach(audios, (audio) => {
        tracks.push({
          label: audio,
          language: audio,
          active: this.playback.audio === audio
        })
      })

      return tracks
    },

    setThumbnail (src) {
      this.playback.thumbnail = src
    },

    setAudioLanguage (audio, silent) {
      this.$player.configure({
        preferredAudioLanguage: audio,
        abr: { enabled: false }
      })

      this.playback.audio = audio
      this.$player.selectAudioLanguage(audio)

      if (silent) return

      this.$emit('player:audiochanged', audio)
    },

    getTextTracks () {
      return this.$player ? this.$player.getTextTracks() : []
    },

    setTextLanguage (subtitle, silent) {
      const isTextVisibility = subtitle !== 'off'
      const tracks = this.getTextTracks()

      _forEach(tracks, (track) => {
        if (['subtitle', 'subtitles'].indexOf(track.kind) === -1 || track.language !== subtitle) {
          return
        }

        this.$player.selectTextLanguage(subtitle)
      })

      this.playback.subtitle = subtitle
      this.$player.setTextTrackVisibility(isTextVisibility)

      if (silent) return

      this.$emit('player:subtitlechanged', subtitle)
    },

    getSourceTracks () {
      const { index, playlists, audio } = this.playback
      const sourceTracks = playlists[index].sourceTracks || []

      var tracks = []

      _forEach(sourceTracks, (sourceTrack) => {
        if (!Array.isArray(sourceTrack.sources) || !sourceTrack.sources.length) {
          return
        }

        var selected = sourceTrack.sources[0]

        tracks.push({
          label: sourceTrack.label,
          language: sourceTrack.language,
          streamURL: selected.streamURL,
          streamMimeType: selected.streamMimeType,
          ...(selected.keySystems ? { keySystems: selected.keySystems } : null),
          active: sourceTrack.language === audio
        })
      })

      return tracks
    },

    async setSourceLanguage (audio) {
      var track = _find(this.getSourceTracks(), { language: audio })

      this.$player.configure({
        drm: { servers: track.keySystems || {} }
      })

      try {
        await this.$player.load(track.streamURL, this.currentTime(), track.streamMimeType)
      } catch (e) {
        this.handlePlayerError(e)
        return
      }

      this.playback.audio = audio
      this.$emit('player:sourcechanged', audio)
    },

    getSeekRange () {
      return this.$player ? this.$player.seekRange() : { start: 0, end: 0 }
    },

    userActive (active) {
      if (typeof active === 'undefined') {
        return this.isUserActive
      }

      this._clearUserActivity()

      if (active) {
        this.handleUserActivity_ = setTimeout(() => {
          const skipButton = this.$refs.skipButton
          const nextActiveElement = this.showSkipButton ? skipButton.$el : this.$el

          this.userActive(false)
          this.$nextTick(() => nextActiveElement.focus())
        }, PLAYER_USER_ACTIVITY)
      }

      if (this.isUserActive === active) {
        return
      }

      this.isUserActive = active
      this.$emit('player:useractive', active)
    },

    setIndex (index) {
      if (typeof index !== 'undefined') {
        this.playback.index = index
      }
    },

    currentIndex () {
      return this.playback.index
    },

    setPlaylists (lists) {
      if (!Array.isArray(lists) || lists.length === 0) {
        return
      }

      this.isPlaylistLoaded = false

      this.playback.playlists = lists
      this.$emit(this.hasVideoStarted ? 'player:playlistchanged' : 'player:setplaylist')

      this._loadPlaylistTrack(this.currentIndex())
    },

    getPlaylists () {
      return this.playback.playlists
    },

    currentTime (seconds) {
      const videoElement = this.$refs.video

      if (typeof seconds === 'undefined') {
        return videoElement.currentTime
      }

      videoElement.currentTime = seconds
    },

    nextEpisode () {
      if (!this.isPlaylistLoaded) {
        return
      }

      this.isVideoPaused = true
      this.isPlaylistLoaded = this.showSkipButton = false
      this.playback.autoplay = true

      this._loadPlaylistTrack(this.playback.index + 1, 0)
    },

    reload () {
      this.isVideoError = this.isVideoEnded = false
      this._loadPlaylistTrack(this.currentIndex(), this.currentTime())

      this.$emit('player:reload')
    },

    replay () {
      this.isVideoPaused = this.isVideoEnded = false
      this._loadPlaylistTrack(0)

      this.$emit('player:replay')
    },

    play (seconds) {
      if (this.isVideoUnload || this.isVideoError) {
        return
      }

      const videoElement = this.$refs.video
      const duration = Math.floor(this.getDuration()) - 1

      if (typeof seconds !== 'undefined') {
        if (seconds < 0) {
          seconds = 0
        } else if (seconds > duration) {
          seconds = duration
        }

        videoElement.currentTime = seconds
      }

      videoElement.play()
    },

    pause () {
      this._clearUserActivity()
      this.$refs.video.pause()
    },

    playOrPause () {
      if (this.isVideoEnded) {
        this.replay()
      } else if (this.isVideoPaused) {
        this.play()
      } else {
        this.pause()
      }
    }
  }
}
</script>

<style lang="scss">
@import './VPlayer';
</style>
