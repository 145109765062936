<template>
  <v-fade-transition
    @fadein="$emit('background:show')"
    @fadeout="$emit('background:hide')"
  >
    <div
      v-show="show"
      class="v-video-background"
    >
      <div class="background-container">
        <v-player
          :shakaOptions="{
            streaming: { preferNativeHls: true }
          }"
          :ui="false"
          @player:playing="$emit('video:playing', $event)"
          @player:ended="handleVideoEnded"
          @player:error="$emit('video:error', $event)"
          autoplay
          ref="player"
        />
      </div>
      <div class="background-overlay"></div>
    </div>
  </v-fade-transition>
</template>

<script>
import VPlayer from '../VPlayer'
import VFadeTransition from '@/transitions/VFadeTransition.vue'

export default {
  name: 'VVideoBackground',

  props: {
    show: Boolean,
    playlist: {
      type: Array,
      default: () => []
    }
  },

  model: {
    prop: 'show',
    event: 'change'
  },

  watch: {
    playlist (list) {
      const player_ = this.$refs.player

      if (list.length) {
        player_.setIndex(0)
        player_.setPlaylists(list)
      } else {
        player_.pause()

        this.$emit('change', false)
      }
    }
  },

  components: {
    VPlayer,
    VFadeTransition
  },

  methods: {
    handleVideoEnded () {
      var index = this.$refs.player.currentIndex()

      if (index === this.playlist.length - 1) {
        this.$emit('change', false)
      }
    },

    getPlayer () {
      return this.$refs.player
    }
  }
}
</script>

<style lang="scss">
@import './VVideoBackground.scss';
</style>
