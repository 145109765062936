export default {
  menu: {
    home: 'หน้าแรก',
    movie: 'หนัง',
    series: 'ซีรีส์',
    upcoming: 'เร็วๆ นี้',
    sport: 'กีฬา',
    live: 'รายการสด',
    favorite: 'รายการโปรด',
    inbox: 'กล่องข้อความ',
    history: 'ประวัติการรับชม',
    search: 'ค้นหา',
    settings: 'ตั้งค่า',
    exit: 'ออก'
  },
  labels: {
    new: 'ล่าสุด',
    view_more: 'ดูเพิ่มเติม',
    day: {
      sun: 'วันอาทิตย์',
      mon: 'วันจันทร์',
      tue: 'วันอังคาร',
      wed: 'วันพุธ',
      thu: 'วันพฤหัสบดี',
      fri: 'วันศุกร์',
      sat: 'วันเสาร์'
    }
  },
  texts: {
    no_items: 'ไม่มีรายการข้อมูล'
  },
  buttons: {
    back_to_home: 'กลับไปหน้าแรก'
  },
  upcoming: {
    texts: {
      coming_soon: '[รับชมได้วันที่] {date} เวลา {time} น.'
    },
    buttons: {
      reminder: 'แจ้งเตือน'
    }
  },
  login: {
    qrcode: {
      header: {
        title: 'เข้าสู่ระบบด้วย QR Code',
        subtitle: 'แสกน QR Code ด้วยมือถือหรือแท็บเล็ต<br>เพื่อเชื่อมต่อบัญชีเข้าใช้งานทีวี'
      },
      labels: {
        separate: 'หรือ'
      },
      texts: {
        signin_timeout: 'กรุณาทำรายการภายใน {second} วินาที',
        qrcode_alt: 'QR Code สำหรับเข้าสู่ระบบบนทีวี LG'
      },
      buttons: {
        retry: 'ลองใหม่อีกครั้ง',
        signin_page: 'เข้าสู่ระบบด้วย Username / Password ที่นี่'
      }
    },
    username: {
      header: {
        title: 'เข้าสู่ระบบด้วยชื่อผู้ใช้'
      },
      form: {
        username: {
          label: 'ชื่อผู้ใช้',
          placeholder: 'รหัสผู้ใช้, อีเมล หรือเบอร์โทรศัพท์'
        },
        password: {
          label: 'รหัสผ่าน',
          placeholder: 'รหัสผ่าน'
        }
      },
      buttons: {
        login: 'เข้าสู่ระบบ'
      },
      errors: {
        login_no_input: 'กรุณากรอกชื่อผู้ใช้และรหัสผ่าน',
        login_failed: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง'
      }
    },
    texts: {
      contact: 'สอบถามเพิ่มเติม โทร 021007007 (ทุกวัน เวลา 9.00 - 22.00 น.)'
    }
  },
  live: {
    header: {
      title: 'ช่องทีวีถ่ายทอดสด',
      pretitle: 'LIVE',
    },
    buttons: {
      play: 'เล่น',
      buy_package: 'ซื้อแพ็กเกจเพื่อรับชม'
    }
  },
  search: {
    header: {
      title: 'ค้นหาหนังหรือซีรีส์'
    },
    menu: {
      query: 'ค้นหาตามชื่อเรื่อง',
      filter: 'ตัวกรองการค้นหา'
    },
    headline: {
      populars: 'ติดอันดับการค้นหา',
      results: 'ผลการค้นหา'
    },
    form: {
      query: {
        label: 'ค้นหา',
        placeholder: 'ชื่อหนังหรือซีรีส์'
      },
    }
  },
  inbox: {
    header: {
      pretitle: 'บัญชีของฉัน',
      title: 'กล่องข้อความ'
    },
    texts: {
      received_time: '[เวลา] {time} [น.]',
    }
  },
  settings: {
    header: {
      title: 'ตั้งค่า'
    },
    menu: {
      profile: 'ข้อมูลส่วนตัว',
      language: 'ภาษา',
      subtitle: 'คำบรรยาย',
      devices: 'จัดการอุปกรณ์',
      network: 'ตรวจสอบเน็ตเวิร์ค',
      help: 'ช่วยเหลือ',
      about: 'เกี่ยวกับ',
      logout: 'ออกจากระบบ'
    },
    account: {
      labels: {
        username: 'บัญชีผู้ใช้',
        profile: 'โปรไฟล์',
        package: 'แพ็กเกจใช้งาน',
        package_expired: 'แพ็กเกจหมดอายุ'
      },
      texts: {
        no_package: 'ยังไม่ได้สมัครแพ็กเกจ แสกนเพื่อซื้อ',
        package_expired: 'แพ็กเกจหมดอายุ แสกนเพื่อซื้อ',
        help_purchase: 'หาแพ็กเกจไม่พบ?'
      },
      buttons: {
        buy_package: 'ซื้อแพ็กเกจ'
      }
    },
    language: {
      headline: 'เลือกตั้งค่าภาษา',
      buttons: {
        en: 'อังกฤษ',
        th: 'ไทย'
      }
    },
    subtitle: {
      headline: 'ตั้งค่าคำบรรยาย',
      texts: {
        preview: 'นี่คือตัวอย่างการแสดงคำบรรยาย',
      },
      form: {
        background: {
          label: 'ความโปร่งใสสีพื้นหลัง'
        },
        font_size: {
          label: 'ขนาดตัวอักษร'
        }
      },
      buttons: {
        standard: 'ค่าเริ่มต้น',
        medium: 'ปานกลาง',
        high: 'สูง'
      }
    },
    device: {
      headline: 'จัดการอุปกรณ์',
      labels: {
        device_info: 'อุปกรณ์',
        logged_in: 'วันเข้าใช้งาน',
        current_device: 'อุปกรณ์นี้'
      },
      dialogs: {
        remove: {
          title: 'คุณแน่ใจว่าต้องการนำอุปกรณ์นี้ออกจากการใช้งาน?',
          buttons: {
            cancel: 'ย้อนกลับ',
            accept: 'ลบอุปกรณ์นี้'
          }
        }
      }
    },
    network: {
      title: 'ตรวจสอบเน็ตเวิร์ค',
      description: 'ทดสอบการเชื่อมต่ออินเตอร์เน็ตว่ามีปัญหาใดหรือไม่ ที่อาจทำให้ไม่สามารถใช้ MONOMAX ได้',
      labels: {
        checking_web_server: 'เซิร์ฟเวอร์ของ MONOMAX',
        checking_streaming_server: 'เซิร์ฟเวอร์ Stream ของ MONOMAX',
        checking_drm_server: 'เซิร์ฟเวอร์ DRM ของ MONOMAX',
        checking_cdn_server: 'เซิร์ฟเวอร์ CDN ของ MONOMAX',
        connection: 'การเชื่อมต่ออินเตอร์เน็ต',
        ip: 'IP.',
      },
      buttons: {
        start: 'เริ่มการทดสอบ',
        inprogress: 'ทดสอบอีกครั้ง',
        retry: 'กำลังตรวจสอบเครือข่าย...'
      }
    },
    help: {
      sections: {
        main: {
          headline: 'ศูนย์ช่วยเหลือ',
          description: 'สแกน QR Code เพื่อเข้าสู่ศูนย์ช่วยเหลือ FAQ บนเว็บไซต์',
          faq_qrcode_alt: 'QR Code เพื่อเข้าสู่ศูนย์ช่วยเหลือ FAQ บนเว็บไซต์'
        },
        contact: {
          headline: 'ติดต่อ MONOMAX Call Center',
          description: 'สอบถามเพิ่มเติม โทร 021007007 (ทุกวัน เวลา 9.00 - 22.00 น.)'
        }
      }
    },
    about: {
      labels: {
        version: 'เวอร์ชั่น'
      }
    }
  },
  product: {
    labels: {
      coming_soon: 'รับชมได้เร็วๆ นี้',
      coming_on: '[รับชมได้วันที่] {date}',
      episode_coming_on: '[รับชมได้วันที่] {date}',
      episodes_list: 'ตอนอื่นๆ',
      episode_number: 'ตอนที่ {episode}',
      expired_date: 'ไม่สามารถรับชมได้ เนื่องจากสิทธิ์ในการใช้งานเนื้อหาของภาพยนตร์เรื่องนี้หมดอายุจาก MONOMAX แล้ว',
      remaining_time: 'เหลืออีก {minute} นาที',
      geo_block: 'ไม่สามารถรับชมได้ในพื้นที่ของคุณ',
      collection: 'คอลเลคชั่น {name}',
      buy_package: 'ซื้อแพ็กเกจ',
      upgrade_package: 'รับชมเนื้อหาแบบไม่จำกัด. <span class="text-primary">ซื้อแพ็กเกจ</span>'
    },
    metas: {
      rate: 'เรท {rate}',
      date: 'ปี {year}',
      duration: '{minutes} นาที',
      episodes: '{count} ตอน',
      updated_new: 'อัพเดทถึง {num_new_ep} / {num_episodes} ตอน',
      updated_on: '{num_new_ep} ตอนใหม่ทุกวัน{day}'
    },
    kind: {
      movie: 'หนัง',
      series: 'ซีรีส์',
      tvshow: 'รายการทีวี',
      live: 'รายการสด'
    },
    rate: {
      general: 'ทั่วไป'
    },
    buttons: {
      back: 'กลับหน้าเลือกหนัง',
      play: 'เล่น',
      replay: 'เริ่มเล่นตั้งแต่ต้น',
      resume: 'ดูต่อ',
      resume_series: 'ดูต่อตอนที่ {episode}',
      video_settings: 'เสียงและคำบรรยาย',
      select_episodes: 'เลือกตอน',
      relateds_list: 'รายการที่คล้ายกัน',
      add_favorite: 'เพิ่มเข้ารายการโปรด',
      buy_package: 'ซื้อแพ็กเกจเพื่อรับชม',
      upgrade_package: 'อัพเกรดแพ็กเกจ'
    },
    episode: {
      audios: {
        thai_dubbed: 'พากย์ไทย',
        soundtrack: 'เสียงต้นฉบับ'
      }
    },
    dialogs: {
      video_settings: {
        title_audios: 'เสียง',
        title_subtitles: 'คำบรรยาย'
      },
      screen_limit: {
        title: 'การจำกัดหน้าจอ',
        content: 'บัญชีของคุณมีผู้ใช้งานเกินจำนวนที่กำหนด ยกเลิกการใช้งานบนอุปกรณ์อื่นเพื่อดำเนินการต่อ',
        buttons: {
          back: 'ย้อนกลับ',
          setting_device: 'จัดการอุปกรณ์'
        }
      },
      buy_package: {
        title: 'คุณยังไม่มีแพ็กเกจรับชม กรุณาซื้อแพ็กเกจ',
        buttons: {
          back: 'ย้อนกลับ',
          accept: 'ซื้อแพ็กเกจ',
        }
      },
      buy_fasttrack: {
        title: 'ซื้อเนื้อหา Fast Track รับชมได้ก่อนใคร',
        how_to_title: 'ขั้นตอนการซื้อเนื้อหา Fast Track<br>ผ่านแอปพลิเคชันบนมือถือ',
        content: 'ผ่านแอปพลิเคชันโมโนแมกซ์บนโทรศัพท์มือถือ<br>เมื่อซื้อแล้วรับชมได้บนทุกอุปกรณ์ ',
        how_to_content: {
          stage_1: 'กดเลือกตอนที่มีสัญลักษณ์ Fast Track',
          stage_2: 'กดซื้อเนื้อหา แล้วชำระผ่านแอปพลิเคชันมือถือ'
        },
        caption: 'สแกนเพื่อดาวน์โหลด หรือเปิดแอปพลิเคชัน',
        buttons: {
          close: 'ปิด'
        }
      },
      coming_soon: {
        title: 'ยังไม่ถึงเวลารับชม',
        content: 'อดใจรออีกนิด',
        texts: {
          fasttrack_on: 'ซื้อ Fast Track รับชมเนื้อหาก่อนใคร',
          release_on: 'รับชมเนื้อหาได้'
        },
        buttons: {
          close: 'ตกลง'
        }
      },
      adult_confirm: {
        title: 'เนื้อหานี้มีข้อจำกัด',
        content: 'เนื้อหานี้อาจมีรูปแบบการนำเสนอ ที่ไม่เหมาะสมกับผู้ชมอายุต่ำกว่า 18 ปี เช่น เนื้อหาเกี่ยวกับเรื่องเพศ กิจกรรมอันตราย ภาษาที่ไม่เหมาะสม ซึ่งควรใช้วิจารณญาณในการรับชม หรือคำแนะนำที่ถูกต้องจากผู้ใหญ่ หากต้องการรับชมกรุณายืนยันรหัสผ่านบัญชีของคุณ',
        inputs: {
          password: 'รหัสผ่านบัญชี'
        },
        errors: {
          password_required: 'จำเป็นต้องใช้รหัสผ่าน กรุณากรอกแบบฟอร์มให้ถูกต้อง',
          wrong_password: 'ขออภัย!, รหัสผ่านไม่ถูกต้อง โปรดลองใหม่อีกครั้ง',
          limit_requests: 'ขออภัย! คุณมีคำขอตรวจสอบรหัสผ่านเกินจำนวนครั้งที่กำหนดแล้ว โปรดรอประมาณ 5 นาทีแล้วลองอีกครั้ง',
          unknown: 'ขออภัย!, ไม่สามารถทำขั้นตอนนี้ได้ในขณะนี้ โปรดลองอีกครั้งในภายหลัง.',
        }
      }
    }
  },
  exit: {
    header: {
      title: 'ออกจาก MONOMAX',
      subtitle: 'คุณแน่ใจว่าต้องการออกจาก MONOMAX?'
    },
    buttons: {
      back: 'ย้อนกลับ',
      exit: 'ออก'
    }
  },
  profile: {
    choose_avatar: {
      header: {
        title: 'เลือกรูปภาพโพรไฟล์'
      },
      buttons: {
        back: 'ย้อนกลับ'
      }
    },
    choose_profile: {
      header: {
        title: 'เลือกโพรไฟล์ใช้งาน'
      },
      buttons: {
        choose: 'เลือกโพรไฟล์'
      }
    },
    create_profile: {
      header: {
        title: 'สร้างโพรไฟล์'
      },
      buttons: {
        create: 'สร้างโพรไฟล์'
      }
    },
    edit_profile: {
      header: {
        title: 'แก้ไขโพรไฟล์'
      },
      buttons: {
        edit: 'แก้ไขโพรไฟล์'
      }
    },
    delete_profile: {
      header: {
        title: 'ลบโพรไฟล์'
      },
      buttons: {
        confirm: 'ยืนยัน',
        back: 'ยกเลิก'
      }
    },
    labels: {
      avatar_no: 'แบบที่ {no}',
      avatar_alt: 'รูปภาพโพรไฟล์'
    },
    form: {
      avatar: {
        label: 'รูปภาพโพรไฟล์',
      },
      profile_name: {
        label: 'ชื่อโพรไฟล์',
        placeholder: 'กำหนดชื่อโพรไฟล์'
      },
      is_kid: {
        label: 'สำหรับเด็ก'
      },
      buttons: {
        kid: 'ใช่',
        no_kid: 'ไม่ใช่',
        save: 'บันทึก',
        delete_profile: 'ลบโพรไฟล์'
      },
    },
    errors: {
      avatar_required: 'The avatar is required.',
      profile_name_required: 'The name is required.'
    }
  },
  genre: {
    header: {
      pretitle: 'ประเภทหนัง'
    }
  },
  category: {
    header: {
      pretitle: 'หมวดหนัง'
    }
  },
  tag: {
    header: {
      pretitle: 'แฮชแท็ก'
    }
  },
  people: {
    header: {
      pretitle: 'นักแสดง / ผู้กำกับ'
    }
  },
  favorite: {
    header: {
      pretitle: 'บัญชีของฉัน',
      title: 'รายการโปรด'
    }
  },
  history: {
    header: {
      pretitle: 'บัญชีของฉัน',
      title: 'ประวัติการรับชม'
    }
  },
  shortclips: {
    header: {
      pretitle: 'คลิปสั้น'
    }
  },
  welcome: {
    header: {
      title: 'หนังดี ซีรีส์ดัง พากย์ไทยครบทุกเรื่อง'
    },
    buttons: {
      login: 'เข้าสู่ระบบ',
      signup: 'สมัครสมาชิก'
    }
  },
  signup: {
    header: {
      title: 'สมัครสมาชิกและซื้อแพ็กเกจรับชม',
      subtitle: 'สแกน QR Code จากมือถือหรือแท็บเล็ต<br>เพื่อทำรายการ'
    },
    texts: {
      qrcode_alt: 'QR Code สำหรับเข้าสู่ระบบบนทีวี LG',
      signup_timeout: 'กรุณาทำรายการภายใน {seconds} วินาที',
      contact: 'สอบถามเพิ่มเติม โทร 021007007 (ทุกวัน เวลา 9.00 - 22.00 น.)'
    },
    buttons: {
      retry: 'ลองใหม่อีกครั้ง',
      back: 'ย้อนกลับ'
    }
  },
  consent: {
    header: {
      title: 'ข้อกำหนดเงื่อนไขและนโยบายคุ้มครองข้อมูลส่วนบุคคล',
      subtitle: 'มีการเปลี่ยนแปลงข้อกำหนดและเงื่อนไขในการใช้บริการ เพื่อเป็นไป ตามนโยบายการคุ้มครองข้อมูลส่วนบุคคล'
    },
    texts: {
      term_caption: 'รายละเอียดข้อกำหนดและเงื่อนไขนโยบาย',
      term_qrcode_alt: 'QR Code สำหรับดูรายละเอียดข้อกำหนดและเงื่อนไขนโยบาย MONOMAX',
      term_confirmation: 'ข้าพเจ้าตกลงยอมรับและรับทราบ <span class="text-primary">ข้อกำหนดและเงื่อนไข</span> ในการใช้บริการของ MONOMAX'
    },
    buttons: {
      accept: 'ยอมรับ',
      decline: 'ปฏิเสธ'
    }
  },
  sport: {
    labels: {
      match_status: 'กำลังถ่ายทอดสดอยู่ในขณะนี้',
      match_date: 'D MMMM [เวลา] HH:mm [น.]'
    }
  },
  watch: {
    dialogs: {
      play_limited_track: {
        title: 'เนื้อหานี้ไม่ได้รวมอยู่ในแพ็กเกจของคุณ<br>กรุณาอัพเกรดแพ็กเกจเพื่อรับชม <span class="text-primary">เริ่มต้นเพียงเดือนละ {price} บาท.</span>',
        buttons: {
          proceed: 'อัพเกรดแพ็กเกจ',
          back: 'กลับ'
        }
      }
    }
  },
  purchase: {
    package: {
      title: 'กรุณาซื้อแพ็กเกจเพื่อรับชม',
      labels: {
        step: 'ขั้นตอนที่ {no}'
      },
      texts: {
        step1: 'แสกนคิวอาร์โค้ดผ่านมือถือเพื่อสมัครแพ็กเกจ',
        step2: 'สมัครแพ็กเกจบนมือถือ',
        step3: 'รอระบบนำไปยังหน้ายืนยันการสมัครแพ็กเกจผ่านทีวีและเริ่มรับชมไปอย่างเต็มอิ่มกับ MONOMAX ได้เลย',
        contact_telno: 'โทร 02-100-7007',
        not_updated: 'พบปัญหาหน้าทีวียังไม่อัพเดท?',
        help_purchase: 'หาแพ็กเกจไม่พบ?'
      },
      buttons: {
        buy_package_general: 'สำหรับลูกค้าทั่วไป',
        buy_package_partner: 'สำหรับลูกค้า {partner}',
        back: 'กลับ'
      }
    },
    upgrade_package: {
      title: 'เนื้อหานี้ไม่ได้รวมอยู่ในแพ็กเกจของคุณ<br>กรุณาอัพเกรดแพ็กเกจเพื่อรับชม',
    },
    finish: {
      title: 'ซื้อแพ็กเกจสำเร็จ',
      labels: {
        package_name: 'ชื่อแพ็กเกจ',
        package_expired: 'แพ็กเกจหมดอายุ'
      },
      buttons: {
        ok: 'ตกลง'
      }
    }
  },
  help: {
    purchase_package: {
      title: 'หาแพ็กเกจไม่พบ',
      description: 'ท่านสามารถรับสิทธิพิเศษต่างๆได้ที่ <span class="text-primary">{link}</span><br>เนื่องจากคุณจะไม่สามารถรับสิทธิพิเศษผ่านทางแอปได้<br>ตามนโยบายของ {platform_store} Store',
      buttons: {
        accept: 'เข้าใจแล้ว',
        learn_more: 'เรียนรู้เพิ่มเติม'
      }
    },
    claim_privilege: {
      title: 'วิธีการรับสิทธิพิเศษของคุณ',
      subtitle: 'รับสิทธิผ่านทางเว็บไซต์ของ Monomax',
      texts: {
        step1: '1. ไปยังเว็บไซต์ {link}',
        step2: '2. ล็อกอินด้วยบัญชีผู้ใช้ monomax ของคุณบนหน้าเว็บไซต์',
        step3: '3. หลังจากนั้นให้คุณเลือกสิทธิพิเศษที่คุณต้องการ และกดรับสิทธิบนเว็บไซต์ได้ทันที'
      },
      buttons: {
        accept: 'เข้าใจแล้ว'
      }
    }
  },
  player: {
    labels: {
      audios: 'เสียง',
      subtitles: 'คำบรรยาย'
    },
    audios: {
      soundtrack: 'ซาวด์แทร็ก',
      en: 'อังกฤษ',
      th: 'ไทย',
      ja: 'ญี่ปุ่น',
      zh: 'จีน',
      ko: 'เกาหลี'
    },
    subtitles: {
      off: 'ปิด (ค่าเริ่มต้น)',
      th: 'ภาษาไทย'
    },
    buttons: {
      back: 'ย้อนกลับ',
      reload: 'ลองใหม่อีกครั้ง',
      video_settings: 'เสียงและคำบรรยาย',
      source_settings: 'เลือกภาษา',
      next_episode: 'เล่นตอนถัดไป',
      skip_intro: 'ข้ามบทนำ',
      skip_recap: 'ข้ามช่วงย้อน',
      skip_credit: 'ข้ามเครดิต'
    },
    errors: {
      playback: 'ไม่สามารถเล่นเนื้อหาได้ เนื่องจากเซิร์ฟเวอร์หรือเครือข่ายมีปัญหา<br>กรุณาลองใหม่อีกครั้งในภายหลัง (ข้อผิดพลาด: {code})',
      unsupported: 'ขออภัย!, อุปกรณ์ไม่รองรับการเล่นเนื้อหา! กรุณาลองใหม่อีกครั้งในภายหลัง'
    }
  },
  error: {
    title: 'ระบบไม่สามารถใช้งานได้ในขณะนี้ กรุณาลองใหม่อีกครั้ง',
    buttons: {
      reload: 'ลองใหม่อีกครั้ง'
    }
  }
}
