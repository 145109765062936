const state = {
  trackId: null,
  routeFrom: null
}

const mutations = {
  SET_TRACK_ID (state, id) {
    state.trackId = id
  },

  SET_ROUTE_FROM (state, route) {
    state.routeFrom = route
  },

  RESET_STATE (state) {
    state.trackId = null
    state.routeFrom = null
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
