<template>
  <div
    v-show="show"
    class="v-player--dialog"
  >
    <div
      v-if="closeable"
      class="v-player--dialog--backdrop"
      @click.self="toggle"
    >
    </div>

    <div class="v-player--dialog--container">
      <v-button
        v-if="closeable"
        class="v-player--dialog--close"
        hoverColor="primary"
        rounded
        @click="toggle"
      >
        <span class="v-btn--icon mr-0"></span>
      </v-button>

      <slot />
    </div>
  </div>
</template>

<script>
import VButton from '@/components/VButton'

export default {
  name: 'Dialog',

  props: {
    show: Boolean,
    closeable: {
      type: Boolean,
      default: true
    }
  },

  model: {
    prop: 'show',
    event: 'dialog:toggle'
  },

  components: {
    VButton
  },

  methods: {
    toggle () {
      this.$emit('dialog:toggle', !this.show)
    }
  }
}
</script>
