export const PLAYER_USER_ACTIVITY = 5000
export const PLAYER_TIME_UPDATE = 125
export const PLAYER_STEP_SECONDS = 10
export const PLAYER_SKIP_DELAY_SECONDS = 20
export const PLAYER_SKIP_INTRO = 'intro'
export const PLAYER_SKIP_RECAP = 'recap'
export const PLAYER_SKIP_CREDIT = 'end'
export const PLAYER_SUBTITLE_BACKGROUND_COLOR = 'standard'
export const PLAYER_SUBTITLE_TEXT_SIZE = 'standard'

export const PACKAGE_TIER_STARTER = 'STARTER'
export const PACKAGE_TIER_MINI = 'MINI'
export const PACKAGE_TIER_JUMBO = 'JUMBO'

export const PACKAGE_UPDATED_TIMEOUT = 1000 * 3 // 3 seconds.

export const PUSH_MEMBER_CHANNEL = 'private-member.'
export const PUSH_PACKAGE_UPDATED_EVENT = 'package.updated'

export const REFRESH_STREAM_TIMEOUT = 1000 * 60 * 60 * 3 // 3 hours.
