export default {
  menu: {
    home: 'Home',
    movie: 'Movie',
    series: 'Series',
    upcoming: 'Upcoming',
    shortclips: 'What to watch',
    sport: 'Sport',
    live: 'Live',
    favorite: 'Favorite',
    inbox: 'Inbox',
    history: 'History',
    search: 'Search',
    settings: 'Settings',
    exit: 'Exit'
  },
  labels: {
    new: 'New',
    view_more: 'View More',
    day: {
      sun: 'Sunday',
      mon: 'Monday',
      tue: 'Tuesday',
      wed: 'Wednesday',
      thu: 'Thursday',
      fri: 'Friday',
      sat: 'Saturday'
    }
  },
  texts: {
    no_items: 'No data items were found.'
  },
  buttons: {
    back_to_home: 'Back to Home'
  },
  upcoming: {
    texts: {
      coming_soon: '[Coming on] {date} {time}'
    },
    buttons: {
      reminder: 'REMIND'
    }
  },
  login: {
    qrcode: {
      header: {
        title: 'Sign in with QR Code',
        subtitle: 'Please scan the QR Code from your mobile<br>or tablet to connect your account on TV'
      },
      labels: {
        separate: 'Or'
      },
      texts: {
        signin_timeout: 'Please complete within {second} seconds.',
        qrcode_alt: 'QR Code for login on the LG TV'
      },
      buttons: {
        retry: 'Try again',
        signin_page: 'Sign in with Username / Password'
      }
    },
    username: {
      header: {
        title: 'Sign in with Username'
      },
      form: {
        username: {
          label: 'Username',
          placeholder: 'Username, Email or Phone number'
        },
        password: {
          label: 'Password',
          placeholder: 'Password'
        }
      },
      buttons: {
        login: 'Login'
      },
      errors: {
        login_no_input: 'Please enter Username and Password.',
        login_failed: 'Your username or password is invalid.'
      }
    },
    texts: {
      contact: 'For more information, Call 021007007 (Everyday 9.00 - 22.00)'
    }
  },
  live: {
    header: {
      title: 'TV Channels',
      pretitle: 'Live'
    },
    buttons: {
      play: 'PLAY',
      buy_package: 'Buy Package'
    }
  },
  search: {
    header: {
      title: 'Search'
    },
    menu: {
      query: 'Find by Name',
      filter: 'Filter by Options'
    },
    headline: {
      populars: 'Top Searches',
      results: 'Search Results'
    },
    form: {
      query: {
        label: 'Search',
        placeholder: 'Move or Series name'
      }
    }
  },
  inbox: {
    header: {
      pretitle: 'MY ACCOUNT',
      title: 'Inbox'
    },
    texts: {
      received_time: '{time}'
    }
  },
  settings: {
    header: {
      title: 'Settings'
    },
    menu: {
      profile: 'My Account',
      language: 'Language',
      subtitle: 'Subtitles',
      devices: 'Devices',
      network: 'Network Checking',
      help: 'Help',
      about: 'About',
      logout: 'Logout'
    },
    account: {
      labels: {
        username: 'Username',
        profile: 'Profile',
        package: 'My Package',
        package_expired: 'Expired on'
      },
      texts: {
        no_package: 'The package has not yet been subscribed. Scan to buy.',
        package_expired: 'Your Package has expired. Scan to buy.',
        help_purchase: 'Where`s my special package?'
      },
      buttons: {
        buy_package: 'Buy Package'
      }
    },
    language: {
      headline: 'Language Settings',
      buttons: {
        en: 'English',
        th: 'Thai'
      }
    },
    subtitle: {
      headline: 'Subtitle Settings',
      texts: {
        preview: 'This is an example subtitle display.',
      },
      form: {
        background: {
          label: 'Background Transparent'
        },
        font_size: {
          label: 'Text Size'
        }
      },
      buttons: {
        standard: 'Standard',
        medium: 'Medium',
        high: 'High'
      }
    },
    device: {
      headline: 'Manage Your Devices',
      labels: {
        device_info: 'Devices',
        logged_in: 'Logged In',
        current_device: 'This device'
      },
      dialogs: {
        remove: {
          title: 'Are you sure you want to remove this device from use?',
          buttons: {
            cancel: 'Cancel',
            accept: 'Accept'
          }
        }
      }
    },
    network: {
      headline: 'Check Your Network',
      description: 'Test your internet connections for any problems that might prevent you from using MONOMAX.',
      labels: {
        checking_web_server: 'MONOMAX Server.',
        checking_streaming_server: 'MONOMAX Stream Server.',
        checking_drm_server: 'MONOMAX DRM Server.',
        checking_cdn_server: 'MONOMAX CDN Server.',
        connection: 'Internet connection.',
        ip: 'IP.',
      },
      buttons: {
        start: 'Start Test',
        inprogress: 'Test Again',
        retry: 'Checking Network...'
      }
    },
    help: {
      sections: {
        main: {
          headline: 'Help Center',
          description: 'Please scan the QR Code to access the Help Center FAQ on the website.',
          faq_qrcode_alt: 'the QR Code to access the Help Center FAQ on the website.'
        },
        contact: {
          headline: 'Contact MONOMAX Call Center',
          description: 'For more information, Call 021007007 (Everyday 9.00 - 22.00)'
        }
      }
    },
    about: {
      labels: {
        version: 'Version'
      }
    }
  },
  product: {
    labels: {
      coming_soon: 'Coming Soon',
      coming_on: '[Coming on] {date}',
      episode_coming_on: '[Available on] {date}',
      episodes_list: 'Episodes List',
      episode_number: 'EP. {episode}',
      expired_date: 'Unable to watch since the right of the content usage of this film has expired from MONOMAX.',
      remaining_time: 'Remaining {minute} minutes',
      geo_block: 'Not available in this region.',
      collection: 'Collection {name}',
      buy_package: 'Buy Package',
      upgrade_package: 'Enjoy unlimited content. <span class="text-primary">Subscribe</span>'
    },
    metas: {
      rate: '{rate}',
      date: '{year}',
      duration: '{minutes} Minutes',
      episodes: '{count} Episodes',
      updated_new: 'Updated to {num_new_ep} / {num_episodes} episodes',
      updated_on: '{num_new_ep} New episode every {day}'
    },
    kind: {
      movie: 'Movie',
      series: 'Series',
      tvshow: 'TV Show',
      live: 'Live'
    },
    rate: {
      general: 'General'
    },
    buttons: {
      back: 'Back',
      play: 'Play',
      replay: 'Play from begining',
      resume: 'Resume',
      resume_series: 'Resume EP.{episode}',
      video_settings: 'Audio & Subtitles',
      select_episodes: 'Select Episodes',
      relateds_list: 'Related List',
      add_favorite: 'Add to Favorites',
      buy_package: 'Buy Package',
      upgrade_package: 'Upgrade Package'
    },
    episode: {
      audios: {
        thai_dubbed: 'Thai Dubbed',
        soundtrack: 'Soundtrack'
      }
    },
    dialogs: {
      video_settings: {
        title_audios: 'AUDIOS',
        title_subtitles: 'SUBTITLES'
      },
      screen_limit: {
        title: 'Screen Limit',
        content: 'Your account has exceeded the screen limit. Deactivate on another device to continue.',
        buttons: {
          back: 'Back',
          setting_device: 'Manage Device'
        }
      },
      buy_package: {
        title: 'You don\'t have subscribed to a package, please buy a package.',
        buttons: {
          back: 'Back',
          accept: 'Buy Package'
        }
      },
      buy_fasttrack: {
        title: 'Buy Fast Track to watch before anyone else',
        how_to_title: 'Fast Track content purchasing process<br>Through a mobile application',
        content: 'Via the MONOMAX application on your mobile phone.<br>Once purchased, you can watch it on any device.',
        how_to_content: {
          stage_1: 'Click to select the episode with the Fast Track symbol.',
          stage_2: 'Click to buy content Then pay through the mobile application.'
        },
        caption: 'Scan to download or open the application',
        buttons: {
          close: 'Close'
        }
      },
      coming_soon: {
        title: 'Coming Soon',
        content: 'Be Patient...',
        texts: {
          fasttrack_on: 'Fast Track available on',
          release_on: 'Scheduled episode release on'
        },
        buttons: {
          close: 'OK'
        }
      },
      adult_confirm: {
        title: 'Age-Restricted Content',
        content: 'This content contains age-restricted content that may not be appropriate for viewers under 18 years old. Such content includes uses of harmful or dangerous activities, nudity and sexual content, or vulgar language in some parts or throughout the video which should be advised by an adult. Please sign in to continue to watch age-restricted content.',
        inputs: {
          password: 'Password'
        },
        errors: {
          password_required: 'Password is required, please fill in the form correctly.',
          wrong_password: 'Sorry!, Your password is incorrect. Please try again.',
          limit_requests: 'Sorry!, You have exceeded the number of check password requests. Please wait about 5 minutes and try again.',
          unknown: 'Sorry, this step cannot be performed at this time. Please try again later.',
        }
      }
    }
  },
  exit: {
    header: {
      title: 'Exit MONOMAX',
      subtitle: 'Are you sure that you want to exit MONOMAX?'
    },
    buttons: {
      back: 'Back',
      exit: 'Exit'
    }
  },
  profile: {
    choose_avatar: {
      header: {
        title: 'Choose Avatar'
      },
      buttons: {
        back: 'Back'
      }
    },
    choose_profile: {
      header: {
        title: 'Switch Profile'
      },
      buttons: {
        choose: 'Select Profile'
      }
    },
    create_profile: {
      header: {
        title: 'Create Profile'
      },
      buttons: {
        create: 'Create Profile'
      }
    },
    edit_profile: {
      header: {
        title: 'Edit Profile'
      },
      buttons: {
        edit: 'Edit Profile'
      }
    },
    delete_profile: {
      header: {
        title: 'Delete Profile'
      },
      buttons: {
        confirm: 'Confirm',
        back: 'Cancel'
      }
    },
    labels: {
      avatar_no: 'Type {no}',
      avatar_alt: 'Avatar'
    },
    form: {
      avatar: {
        label: 'Avatar',
      },
      profile_name: {
        label: 'Profile Name',
        placeholder: 'Please enter profile name'
      },
      is_kid: {
        label: 'For Kid'
      },
      buttons: {
        kid: 'Yes',
        no_kid: 'No',
        save: 'Save',
        delete_profile: 'Delete Profile'
      }
    },
    errors: {
      avatar_required: 'The avatar is required.',
      profile_name_required: 'The name is required.'
    }
  },
  genre: {
    header: {
      pretitle: 'Genre'
    }
  },
  category: {
    header: {
      pretitle: 'Category'
    }
  },
  tag: {
    header: {
      pretitle: 'Tag'
    }
  },
  people: {
    header: {
      pretitle: 'Actor / Director'
    }
  },
  favorite: {
    header: {
      pretitle: 'My Account',
      title: 'Favorite'
    }
  },
  history: {
    header: {
      pretitle: 'My Account',
      title: 'Watch History'
    }
  },
  shortclips: {
    header: {
      pretitle: 'Short Clips'
    }
  },
  welcome: {
    header: {
      title: 'Premium Movie & Series with<br>all Thai dubbed'
    },
    buttons: {
      login: 'Login',
      signup: 'Signup'
    }
  },
  signup: {
    header: {
      title: 'Register and Buy package',
      subtitle: 'Please scan the QR Code from your mobile or<br>tablet to continue making a transaction.',
    },
    texts: {
      qrcode_alt: 'QR Code for login on the LG TV',
      signup_timeout: 'Please complete within {seconds} seconds.',
      contact: 'For more information, Call 021007007 (Everyday 9.00 - 22.00)'
    },
    buttons: {
      retry: 'Retry',
      back: 'Back'
    }
  },
  consent: {
    header: {
      title: 'Term and Conditions and Privacy Policy',
      subtitle: 'We have made some changes in the Terms and conditions of MONOMAX according to Personal Data Protection Policy'
    },
    texts: {
      term_caption: 'Term and Conditions and Privacy Policy',
      term_qrcode_alt: 'QR Code for checking the term and conditions and privacy policy of MONOMAX service',
      term_confirmation: 'I hereby agree and accept <span class="text-primary">Terms and conditions</span> of MONOMAX service.'
    },
    buttons: {
      accept: 'Accept',
      decline: 'Decline'
    }
  },
  sport: {
    labels: {
      match_status: 'Broadcasting live on now',
      match_date: 'D MMMM HH:mm a.'
    }
  },
  watch: {
    dialogs: {
      play_limited_track: {
        title: 'This content is not included in your current package.<br>Upgrade for exclusive content. <span class="text-primary">Starting at {price} Baht/month.</span>',
        buttons: {
          proceed: 'Upgrade Package',
          back: 'Back'
        }
      }
    }
  },
  purchase: {
    package: {
      title: 'Subscribe to enjoy watching.',
      labels: {
        step: 'Step {no}'
      },
      texts: {
        step1: 'Scan the QR code on your mobile to subscribe to the package.',
        step2: 'Subscribe to the package on your mobile device.',
        step3: 'Wait for the TV to display the package subscription confirmation and start enjoying MONOMAX',
        contact_telno: 'Call 02-100-7007',
        not_updated: 'not updated yet?'
      },
      buttons: {
        buy_package_general: 'General Customers',
        buy_package_partner: '{partner} Customers',
        back: 'Back'
      }
    },
    upgrade_package: {
      title: 'This content is not in your current package.<br>Subscribe to enjoy watching.',
    },
    finish: {
      title: 'Success, You\'re now subscribed.',
      labels: {
        package_name: 'Package',
        package_expired: 'Due date'
      },
      buttons: {
        ok: 'Confirm'
      }
    }
  },
  help: {
    purchase_package: {
      title: 'Where\'s my special package?',
      description: 'You can receive special privileges at <span class="text-primary">{link}</span><br> as you won\'t be able to obtain special privileges through the app,<br> following {platform_store} Store\'s policy.',
      buttons: {
        accept: 'Understand',
        learn_more: 'Learn More'
      }
    },
    claim_privilege: {
      title: 'How to claim your special privileges.',
      subtitle: 'Get privileges on Monomax website.',
      texts: {
        step1: '1. Go to the website {link}',
        step2: '2. Log in with your monomax user account on the website.',
        step3: '3. After that, select the special privileges you want and click "Claim" on the website immediately.'
      },
      buttons: {
        accept: 'Understand'
      }
    }
  },
  player: {
    labels: {
      audios: 'Audios',
      subtitles: 'Subtitles'
    },
    audios: {
      soundtrack: 'Soundtrack',
      en: 'English',
      th: 'Thai',
      ja: 'Japanese',
      zh: 'Chinese',
      ko: 'Korean'
    },
    subtitles: {
      off: 'Off (Default)',
      th: 'Thai'
    },
    buttons: {
      back: 'Back',
      reload: 'Reload',
      video_settings: 'Audios & Subtitles',
      source_settings: 'Languages',
      next_episode: 'Next Episode',
      skip_intro: 'Skip Intro',
      skip_recap: 'Skip Recap',
      skip_credit: 'Skip Credit'
    },
    errors: {
      playback: 'The video playback could not played due to server or network problems.<br>Please try again later. (Error Code: {code})',
      unsupported: 'Sorry!, Your device is not supported! Please try again later.'
    }
  },
  error: {
    title: 'The system is not available at this time. Please try again.',
    buttons: {
      reload: 'Reload Page'
    }
  }
}
