<template>
  <div
    class="v-player--seekbar"
    @mousedown="handleMouseDown"
    @mousemove="handleMouseMove"
    @keydown="handleKeyDown"
    @keyup="handleKeyUp"
  >
    <div
      class="v-player--seekbar--load-progress"
      :style="loadProgressStyles"
    >
    </div>
    <div
      class="v-player--seekbar--play-progress"
      :style="playProgressStyles"
    >
      <div class="v-player--seekbar--slider-button" ref="seekBarButton"></div>
      <div class="v-player--seekbar--time-tooltip">
        {{ time | formatTime }}
      </div>
    </div>
    <div
      class="v-player--seekbar--mouse-display"
      :style="mouseDisplayStyles"
    >
      <div class="v-player--seekbar--time-tooltip">
        {{ previewTime | formatTime }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  keyCodes,
  formatTime,
  convertPositionToScale
} from '@/utils/helpers'

import {
  PLAYER_TIME_UPDATE,
  PLAYER_STEP_SECONDS
} from '@/utils/constants'

export default {
  name: 'SeekBar',

  data () {
    return {
      isChanging: false,
      isMouseChanging: false,
      time: 0,
      previewTime: 0,
      duration: 0,
      load: {
        start: 0,
        end: 0
      }
    }
  },

  computed: {
    loadProgressStyles () {
      const barOffset = (this.load.start / this.duration) * 100
      const barWidth = ((this.load.end - this.load.start) / this.duration) * 100

      return {
        left: `${isNaN(barOffset) ? 0 : barOffset.toFixed(2)}%`,
        width: `${isNaN(barWidth) ? 0 : barWidth.toFixed(2)}%`,
      }
    },

    playProgressStyles () {
      const barWidth = (this.time / this.duration) * 100

      return { width: `${isNaN(barWidth) ? 0 : barWidth.toFixed(2)}%` }
    },

    mouseDisplayStyles () {
      const barWidth = (this.previewTime / this.duration) * 100

      return { left: `${isNaN(barWidth) ? 0 : barWidth.toFixed(2)}%` }
    }
  },

  filters: {
    formatTime
  },

  mounted () {
    this.$parent.$on('player:playlistonload', this.handlePlaylistOnload)

    this.$parent.$on([
      'player:durationchanged',
      'player:timeupdate',
    ], this.handleVideoTimeUpdate)

    this.$parent.$on('player:durationchanged', this.handleVideoDurationUpdate)
    this.$parent.$on('player:progress', this.handleVideoProgressUpdate)
  },

  beforeDestroy () {
    this.$parent.$off('player:playlistonload', this.handlePlaylistOnload)

    this.$parent.$off([
      'player:durationchanged',
      'player:timeupdate',
    ], this.handleVideoTimeUpdate)

    this.$parent.$off('player:durationchanged', this.handleVideoDurationUpdate)
    this.$parent.$off('player:progress', this.handleVideoProgressUpdate)
  },

  methods: {
    _clearVideoSeekingAfter () {
      this.handleVideoSeekingAfter_ && clearTimeout(this.handleVideoSeekingAfter_)
      this.handleVideoSeekingAfter_ = null
    },

    handleMouseDown (e) {
      this.isMouseChanging = true
      this.update(e.clientX)
    },

    handleMouseMove (e) {
      this.previewTime = this.duration * convertPositionToScale(this.$el, e.clientX)
    },

    handleKeyDown (e) {
      const keycode = e.keyCode || e.which

      if (keycode === keyCodes.right) {
        e.stopPropagation()

        this.isChanging = true
        this.seek(this.time + PLAYER_STEP_SECONDS)
      } else if (keycode === keyCodes.left) {
        e.stopPropagation()

        this.isChanging = true
        this.seek(this.time - PLAYER_STEP_SECONDS)
      }
    },

    handleKeyUp (e) {
      const keycode = e.keyCode || e.which

      if (keycode === keyCodes.right || keycode === keyCodes.left) {
        this.isChanging = false
        this._clearVideoSeekingAfter()
        this.handleVideoSeekingAfter()
      }
    },

    handlePlaylistOnload () {
      this.time = this.duration = this.previewTime = this.load.start = this.load.end = 0
    },

    handleVideoTimeUpdate () {
      const player_ = this.$parent

      if (!player_.isVideoSeeking) {
        this.time = this.$parent.currentTime()
      }
    },

    handleVideoDurationUpdate () {
      this.duration = this.$parent.getDuration()
    },

    handleVideoProgressUpdate () {
      const player_ = this.$parent

      if (player_.isLive()) return

      const buffered = player_.getBufferedInfo()
      const bufferedLength = buffered.total.length

      this.load.start = bufferedLength ? buffered.total[0].start : 0
      this.load.end = bufferedLength ? buffered.total[bufferedLength - 1].end : 0
    },

    handleVideoSeekingAfter () {
      const player_ = this.$parent

      player_.currentTime(this.time)

      if (this.isChanging || this.isMouseChanging) {
        return
      }

      player_.isVideoSeeking = false
      player_.$emit('player:seekend')
    },

    rewind () {
      this.seek(this.time - PLAYER_STEP_SECONDS)
    },

    forward () {
      this.seek(this.time + PLAYER_STEP_SECONDS)
    },

    seek (seconds) {
      this._clearVideoSeekingAfter()

      const player_ = this.$parent
      const duration = Math.floor(this.duration) - 1

      if (isNaN(duration)) return

      player_.pause()

      if (typeof seconds === 'undefined' || seconds < 0) {
        seconds = 0
      } else if (seconds > duration) {
        seconds = duration
      }

      this.time = seconds

      player_.isVideoEnded = false
      player_.isVideoSeeking = true

      this.handleVideoSeekingAfter_ = setTimeout(() => this.handleVideoSeekingAfter(), PLAYER_TIME_UPDATE)

      player_.$emit('player:seeking')
    },

    update (position) {
      if (typeof position === 'number') {
        this.seek(this.duration * convertPositionToScale(this.$el, position))
      }
    }
  }
}
</script>
