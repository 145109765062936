import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import VueMatomo from 'vue-matomo'
import VueSpatialNavigation from './plugins/vue-spatial-navigation'
import VueMoment from './plugins/vue-moment'
// import VuePusher from './plugins/vue-pusher'
import 'intersection-observer'
import 'velocity-animate'
import './_webos'
import './api'
import './_fontawesome'
import './assets/scss/main.scss'
import router from './router'
import store from './store'
import i18n from './i18n'
import App from './App.vue'

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  release: process.env.VUE_APP_SENTRY_RELEASE || 'no-job-id',

  integrations: [new Sentry.BrowserTracing()],

  // Browser Tracing
  tracesSampleRate: process.env.NODE_ENV === 'production' ? .25 : 1,
  tracePropagationTargets: ['localhost', 'ngrok-free.app', 'spa.monomax.me'],

  denyUrls: [
    /chunk-vendors/,
    /node_modules/
  ],

  ignoreErrors: [
    /^ChunkLoadError/i,
    /play\(\)/i,
    'The operation was aborted.'
  ]
})

Vue.config.productionTip = false

Vue.use(VueSpatialNavigation)
Vue.use(VueMoment)

// Vue.use(VuePusher, {
//   apiKey: process.env.VUE_APP_PUSHER_API_KEY,
//   options: {
//     cluster: 'ap1',
//     channelAuthorization: { endpoint: process.env.VUE_APP_PUSHER_CHANNEL_AUTH },
//   },
//   autoConnect: false
// })

if (process.env.NODE_ENV === 'production') {
  Vue.use(VueMatomo, {
    siteId: process.env.VUE_APP_MATOMO_SITE_ID,
    trackerScriptUrl: '/tracker.js',
    trackerUrl: process.env.VUE_APP_MATOMO_BASE_URL
  })
}

new Vue({
  router,
  store,
  i18n,

  beforeCreate () {
    this.$store.dispatch('FETCH_DEVICE')
      .then((device) => {
        if (!device) return

        if ('webOS' in window && webOS.platform.tv) {
          Sentry.setContext('os', {
            name: 'Web0S',
            version: device.version
          })
        }
      })
  },

  render: h => h(App)
}).$mount('#app')
