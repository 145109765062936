import {
  fetchProduct,
  fetchStream,
  fetchRelated,
  addProductReminder,
  removeProductReminder,
  sendWatch
} from '../api'

import { PACKAGE_TIER_STARTER } from '@/utils/constants'

import { createSourceTrackPlaylist } from '@/utils/helpers'

import _filter from 'lodash/filter'
import _find from 'lodash/find'
import _findIndex from 'lodash/findIndex'
import _forEach from 'lodash/forEach'
import _map from 'lodash/map'
import _maxBy from 'lodash/maxBy'

const namespaced = true

const state = {
  current: null,
  streams: [],
  relateds: []
}

const mutations = {
  SET_PRODUCT (state, product) {
    state.current = product
  },

  SET_PRODUCT_STREAMS (state, streams) {
    state.streams = streams
  },

  SET_PRODUCT_RELATEDS (state, relateds) {
    state.relateds = relateds
  },

  SET_PRODUCT_FAVORITE (state, status) {
    if (state.current) state.current.is_favorited = status
  },

  RESET_STATE (state) {
    state.current = null
    state.streams = []
    state.relateds = []
  }
}

const actions = {
  FETCH_PRODUCT ({ commit }, { id }) {
    return fetchProduct(id)
      .then((res) => res.data)
      .then((product) => commit('SET_PRODUCT', product))
  },

  FETCH_PRODUCT_STREAMS ({ dispatch, commit, getters, rootGetters }, { id }) {
    return dispatch('auth/FETCH_USER', null, { root: true })
      .then(() => fetchStream(id, rootGetters.isDrmSupported))
      .then((res) => res.data)
      .then((data) => {
        let streams = []

        if (getters.isProductSeries) {
          streams = data.episodes
        } else {
          data.id = id
          streams.push(data)
        }

        commit('SET_PRODUCT_STREAMS', streams)
      })
      .catch((e) => {
        const error = e.response || {}

        if (error.status === 400 || error.status === 401) {
          dispatch('auth/LOGOUT', null, { root: true })
        }
      })
  },

  FETCH_PRODUCT_RELATEDS ({ state, commit, rootGetters }, { id }) {
    if (state.relateds.length) {
      return Promise.resolve()
    }

    return fetchRelated(id)
      .then((res) => res.data || {})
      .then((lists) => {
        const activeListNames = ['sequels', 'related']

        var newLists = []

        _forEach(activeListNames, (listName) => {
          const list = lists[listName]

          if (!list) return

          newLists.push({
            title: list.title,
            type: 'product',
            slug: listName,
            items: !rootGetters.isAllowedAdultContents
              ? _filter(list.items || [], (i) => i.rate !== 'RATE_18' && i.rate !== 'RATE_20')
              : list.items
          })
        })

        return newLists
      })
      .then((relateds) => commit('SET_PRODUCT_RELATEDS', relateds))
  },

  ADD_PRODUCT_REMINDER (ctx, { id }) {
    return addProductReminder(id)
  },

  REMOVE_PRODUCT_REMINDER (ctx, { id }) {
    return removeProductReminder(id)
  },

  SEND_WATCH (ctx, { id, episodeId, pos, len }) {
    return sendWatch(id, episodeId, pos, len)
  }
}

const getters = {
  isProductExisted (state) {
    return !!state.current
  },

  isAvailability (state, getters) {
    const { availability, episodes } = state.current || {}

    if (!getters.isProductExisted || !availability) {
      return
    }

    const productAvailability = availability.status === true
    const episodeAvailability = _filter(episodes || [], (o) => o.availability.status === true).length > 0

    return getters.isProductSeries ? productAvailability && episodeAvailability : productAvailability
  },

  isAllowFastTrack (state, getters) {
    return getters.isProductExisted ? state.current.allow_fasttrack : false
  },

  isComingSoon (state, getters) {
    const { availability } = state.current || {}

    if (!getters.isProductExisted || !availability) {
      return false
    }

    const end = availability.end ? new Date(availability.end) : null
    const now = new Date()

    return availability.start === null || end !== null && now.getTime() < end.getTime()
  },

  isRateContent (state, getters) {
    return getters.isProductExisted ? state.current.rate === 'RATE_18' || state.current.rate === 'RATE_20' : false
  },

  isProductSeries (state, getters) {
    return getters.isProductExisted ? state.current.kind === 'series' : false
  },

  isUpgradePackage (state, getters) {
    if (!getters.isProductExisted) {
      return false
    }

    const { has_active_package, member } = state.current

    return has_active_package && member.package_tier === PACKAGE_TIER_STARTER
  },

  isWatchTierAllowed (state, getters) {
    const product = state.current

    if (!product || !product.has_active_package) {
      return false
    }

    var allowed = false

    if (getters.isProductSeries) {
      allowed = !!_filter(product.episodes || [], (o) => o.watch_tier_allowed === true).length
    } else {
      allowed = product.watch_tier_allowed === true
    }

    return allowed
  },

  hasWatchHistory (state, getters) {
    return !!getters.lastWatchTime
  },

  lastWatchTime (state, getters) {
    const product = state.current
    const { isProductSeries, lastWatchEpisode } = getters

    var progress = null

    if (product) {
      if (isProductSeries) {
        progress = lastWatchEpisode ? lastWatchEpisode.progress : null
      } else {
        progress = product.progress
      }
    }

    return progress
  },

  isNativePlaylist () {
    return false
  },

  lastWatchEpisode (state, getters) {
    return _maxBy(getters.activeEpisodes, (o) => o.progress ? new Date(o.progress.updated_at) : null)
  },

  limitedTrackIds (state, getters) {
    var activeIds = []

    if (getters.isProductExisted) {
      const product = state.current || {}

      if (getters.isProductSeries) {
        _forEach(product.episodes || [], (episode) => {
          if (episode.watch_tier_allowed === false) {
            activeIds.push(episode.id)
          }
        })
      } else {
        if (product.watch_tier_allowed === false) {
          activeIds.push(product.id)
        }
      }
    }

    return activeIds
  },

  fastTrackIds (state, getters) {
    const { episodes } = state.current || {}

    var activeIds = []

    _forEach (episodes || [], (episode) => {
      const { fasttrack, availability } = episode
      const hasFastTrack = typeof fasttrack !== 'undefined' && fasttrack !== null
      const hasAvailability = typeof availability !== 'undefined' && availability !== null

      if (!hasFastTrack || !hasAvailability) {
        return
      }

      const isTrackAvailable = availability && availability.status === true
      const isFastTrack = !isTrackAvailable && typeof fasttrack.start_at !== 'undefined'
      const isFastTrackPurchased = fasttrack.can_buy === false && typeof fasttrack.purchase_at !== 'undefined'

      if (getters.isAllowFastTrack && isFastTrack && !isFastTrackPurchased) {
        activeIds.push(episode.id)
      }
    })

    return activeIds
  },

  activeFastTrackIds (state, getters) {
    const { episodes } = state.current || {}

    var activeIds = []

    _forEach(getters.fastTrackIds, (id) => {
      const { fasttrack } = _find(episodes, { id })
      const now = new Date()
      const rangeStart = new Date(fasttrack.start_at)

      if (!isNaN(rangeStart) && now.getTime() >= rangeStart.getTime()) {
        activeIds.push(id)
      }
    })

    return activeIds
  },

  activeEpisodes (state) {
    const { episodes } = state.current || {}
    return _filter(episodes || [], (o) => o.availability && o.availability.status === true)
  },

  activeIndex (state, getters) {
    const { episodes } = state.current || {}
    const { isProductSeries, hasWatchHistory, lastWatchEpisode } = getters

    return isProductSeries && hasWatchHistory
      ? _findIndex(episodes || [], { id: lastWatchEpisode.id })
      : 0
  },

  activeAudios (state, getters) {
    const { activePlaylist } = getters

    var audios = []

    if (activePlaylist.length) {
      audios = activePlaylist[0].audios || []
    }

    return audios
  },

  activeSubtitles (state, getters) {
    const { activePlaylist } = getters

    var subtitles = []

    if (activePlaylist.length) {
      subtitles = _map(activePlaylist[0].subtitles || [], (subtitle) => subtitle.language)
    }

    return subtitles
  },

  activePlaylist (state, getters, rootState, rootGetters) {
    const product = state.current
    const productStreams = state.streams
    const isDrmSupported = rootGetters.isDrmSupported

    var playlist = []

    if (product !== null) {
      if (getters.isProductSeries) {
        playlist = _map(product.episodes || [], (o) => ({
          id: o.id,
          title: product.title,
          episodeTitle: o.title,
          thumbnail: o.thumbnail,
          ...(o.progress ? { progress: o.progress } : {}),
          availability: o.availability.status,
          watch_tier_allowed: o.watch_tier_allowed
        }))
      } else {
        playlist = [{
          id: product.id,
          title: product.title,
          thumbnail: product.thumbnail,
          ...(product.progress ? { progress: product.progress } : {}),
          availability: product.availability.status,
          watch_tier_allowed: product.watch_tier_allowed
        }]
      }

      _forEach(playlist, (list, index) => {
        var { id, availability } = list
        var { streams, skippable } = _find(productStreams, { id }) || {}

        if (!availability || !streams) return

        var audios = [], audioFallbacks = [], subtitles = [], sources = [], sourceTracks = [], fallback = [], fallbackTracks = []
        var streamHls = streams.hls || {}, streamDash = streams.dash || {}

        // Main Stream with the DRM protection.
        if (isDrmSupported) {
          var streamDashAuto = _filter(streamDash.auto || [], (o) => o.drm_url !== undefined)

          if (streamDashAuto.length) {
            var stream = _maxBy(_filter(streamDashAuto, { server: process.env.VUE_APP_MONOMAX_STREAM_SERVER }), (o) => o.bitrate === 'auto' ? 9999 : o.bitrate) || _maxBy(streamDashAuto, (o) => o.bitrate === 'auto' ? 9999 : o.bitrate)

            if (stream) {
              sources = [{
                streamURL: stream.link,
                streamMimeType: 'application/dash+xml',
                keySystems: { 'com.widevine.alpha': stream.drm_url }
              }]

              audios = stream.audios || []
              subtitles = stream.subtitles || []
            }
          } else {
            sourceTracks = createSourceTrackPlaylist(streamDash, 'dash', true)
            audios = _map(sourceTracks, (o) => o.language)
          }
        }

        // Fallback Stream without the DRM Protection.
        var streamHlsAuto = _filter(streamHls.auto || [], (o) => o.drm_url === undefined)

        if (streamHlsAuto.length) { // Source Auto
          stream = _maxBy(_filter(streamHlsAuto, { server: process.env.VUE_APP_MONOMAX_STREAM_SERVER }), (o) => o.bitrate === 'auto' ? 9999 : o.bitrate) || _maxBy(streamHlsAuto, (o) => o.bitrate === 'auto' ? 9999 : o.bitrate)

          if (stream) {
            fallback = [{
              streamURL: stream.link,
              streamMimeType: 'application/x-mpegurl',
            }]

            audioFallbacks = stream.audios || []
            subtitles = subtitles.length ? subtitles : stream.subtitles

            // if main stream is empty then replace with the fallback stream.
            if (!sources.length && !sourceTracks.length) {
              sources = fallback
              audios = audioFallbacks

              fallback = audioFallbacks = []
            }
          }
        } else { // Source sourceTracks
          fallbackTracks = createSourceTrackPlaylist(streamHls, 'hls')
          audioFallbacks = _map(fallbackTracks, (o) => o.language)

          // if main stream is empty then replace with the fallback stream.
          if (!sources.length && !sourceTracks.length) {
            sourceTracks = fallbackTracks
            audios = audioFallbacks

            fallbackTracks = audioFallbacks = []
          }
        }

        if (audios.length) {
          playlist[index].audios = audios
        }

        if (audioFallbacks.length) {
          playlist[index].audioFallbacks = audioFallbacks
        }

        if (subtitles.length) {
          playlist[index].subtitles = subtitles
        }

        if (sources.length) {
          playlist[index].sources = sources
        } else if (sourceTracks.length) {
          playlist[index].sourceTracks = sourceTracks
        }

        if (fallback.length) {
          playlist[index].fallback = fallback
        } else if (fallbackTracks.length) {
          playlist[index].fallbackTracks = fallbackTracks
        }

        if (skippable) {
          playlist[index].skip = skippable
        }
      })
    }

    return _filter(playlist, { availability: true })
  }
}

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters
}
