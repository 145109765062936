<template>
  <router-link
    v-slot="{ navigate }"
    :to="{
      name: 'product',
      params: { id: this.clip.product.id }
    }"
    custom
  >
    <v-card
      v-sn-focusable
      class="v-carousel--slide--link clipitem"
      data-sn-autofocus="disable"
      @sn:deactive.native="handleDeactive"
      @sn:active.native="handleActive"
      @keypress.enter.native="navigate"
      @click.native="navigate"
      role="link"
    >
      <div class="clipitem--preview">
        <div class="clipitem--preview--video">
          <v-player
            v-if="activePlayer"
            :shakaOptions="{
              streaming: { preferNativeHls: true }
            }"
            :ui="false"
            :playlists="[{
              sources: [{
                streamURL: clip.stream_url,
                streamMimeType: 'application/x-mpegurl'
              }]
            }]"
            @player:playing="showVideo = true"
            @player:ended="showVideo = false"
            @player:error="activePlayer = false"
            autoplay
            ref="player"
          />
        </div>

        <v-fade-transition
          v-show="!showVideo"
          @fadein="activePlayer = false"
        >
          <div class="image clipitem--preview--image">
            <div class="image--wrapper">
              <img
                class="img-lazy"
                :data-lazy="clip.thumbnail"
                :alt="clip.title"
              >
            </div>

            <div class="image--icon">
              <font-awesome-icon icon="circle-play" />
            </div>
          </div>
        </v-fade-transition>
      </div>

      <div class="v-card--title px-0 py-2">
        {{ clip.title }}
      </div>
    </v-card>
  </router-link>
</template>

<script>
import VCard from '@/components/VCard'
import VPlayer from '@/components/VPlayer'
import VFadeTransition from '@/transitions/VFadeTransition.vue'

export default {
  name: 'ClipItem',

  props: {
    index: Number,
    clip: Object
  },

  data () {
    return {
      showVideo: false,
      activePlayer: false
    }
  },

  components: {
    VCard,
    VPlayer,
    VFadeTransition
  },

  methods: {
    _clearShowTimer () {
      this.showVideoTimer && clearTimeout(this.showVideoTimer)
      this.showVideoTimer = null
    },

    handleActive () {
      const hasVideoPreview = !!this.clip.stream_url

      if (!hasVideoPreview) {
        return
      }

      this._clearShowTimer()

      this.showVideoTimer = setTimeout(() => this.activePlayer = true, 3000) // 3 seconds
    },

    handleDeactive () {
      this._clearShowTimer()

      if (this.activePlayer) {
        this.$refs.player.pause()
      }

      this.showVideo = false
    },

    goToProductPage () {
      this.$router.push({
        name: 'product',
        params: { id: this.clip.product.id }
      })
    }
  }
}
</script>
