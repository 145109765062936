import Vue from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library, dom } from '@fortawesome/fontawesome-svg-core'

import {
  faHouse, faFilm, faTv, faMagnifyingGlass, faGear, faCircleUser, faHeart, faBasketball, faVideo, faArrowLeft, faArrowRight, faXmark, faBell,
  faStar, faCheck, faCircleCheck, faCircleInfo, faBackwardStep, faShuffle, faSliders, faRotateLeft, faClone, faList, faCircle, faCircleNotch, faClock,
  faPlay, faCirclePlay, faPlus, faHistory, faDeleteLeft, faWindowClose, faArrowCircleLeft, faPowerOff, faTowerBroadcast, faCompass,
  faChevronUp, faChevronDown, faChevronLeft, faChevronRight, faMobileScreenButton, faDesktop, faTrashCan, faLock, faCrown
} from '@fortawesome/free-solid-svg-icons'

import {
  faPenToSquare, faStar as farStar, faBell as farBell
} from '@fortawesome/free-regular-svg-icons'

library.add(
  faHouse, faFilm, faTv, faMagnifyingGlass, faGear, faCircleUser, faHeart, faBasketball, faVideo, faArrowLeft, faArrowRight, faXmark, faBell, farBell,
  faStar, faCheck, faCircleCheck, faCircleInfo, farStar, faBackwardStep, faShuffle, faSliders, faRotateLeft, faClone, faList, faCircle, faCircleNotch, faClock,
  faPlay, faPlus, faPenToSquare, faHistory, faDeleteLeft, faWindowClose, faArrowCircleLeft, faPowerOff, faTowerBroadcast, faCompass,
  faChevronUp, faChevronDown, faChevronLeft, faChevronRight, faMobileScreenButton, faDesktop, faTrashCan, faCirclePlay, faLock, faCrown
)

dom.watch()

Vue.component('font-awesome-icon', FontAwesomeIcon)
