import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './_routes'
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'is-active',
  routes
})

function navigationGuards (to, from, next) {
  document.title = to.meta.title

  store.dispatch('auth/CHECK_AUTH')
    .then((user) => {
      if ('_paq' in window && user) {
        window._paq.push(['setUserId', `${user.id}`])
      }
    })
    .finally(() => {
      const isAuthenticated = store.getters['auth/isAuthenticated']
      const isConsentChecked = store.getters['auth/isConsentChecked']
      const isPlaylistEmpty = store.getters['player/isPlaylistEmpty']

      if (
        (to.name !== 'consent' && to.name !== 'profile-choose' && isAuthenticated && !isConsentChecked)
      ) {
        next({ name: 'consent' })
      } else if (
        (to.name === 'welcome' && isAuthenticated) ||
        (to.name === 'login-qrcode' && isAuthenticated) ||
        (to.name === 'login-username' && isAuthenticated) ||
        (to.name === 'watch' && isPlaylistEmpty) ||
        (to.name === 'inbox' && !isAuthenticated) ||
        (to.name === 'profile-choose' && !isAuthenticated) ||
        (to.name === 'profile-create' && !isAuthenticated) ||
        (to.name === 'profile-edit' && !isAuthenticated) ||
        (to.name === 'profile-delete' && !isAuthenticated) ||
        (to.name === 'profile-avatar' && !isAuthenticated) ||
        (to.name === 'favorite' && !isAuthenticated) ||
        (to.name === 'consent' && (!isAuthenticated || isConsentChecked)) ||
        (to.name === 'purchase-package' && !isAuthenticated) ||
        (to.name === 'purchase-finish' && !isAuthenticated)
      ) {
        next({ name: 'home' })
      } else if (
        (to.name === 'settings' && !isAuthenticated) ||
        (to.name === 'setting-language' && !isAuthenticated) ||
        (to.name === 'setting-devices' && !isAuthenticated) ||
        (to.name === 'setting-network' && !isAuthenticated) ||
        (to.name === 'setting-help' && !isAuthenticated) ||
        (to.name === 'setting-about' && !isAuthenticated)
      ) {
        next({ name: 'welcome', append: true })
      } else {
        next()
      }
    })
}

router.beforeEach(navigationGuards)

export default router
